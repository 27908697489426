import { Column, Container, Row } from '@/components/base/gridview'
import React from 'react'
import { textAndImageProps } from './types'
import { useApp } from '@wap-client/core'
import Image from '@/components/base/image'

const TextAndImage: React.FC<textAndImageProps> = ({
  image,
  subtitle,
  desc,
  title,
}) => {
  const app = useApp()
  return (
    <section className="textandimage">
      <Container size="extended">
        <Row>
          <Column className="left-part">
            <div className="left-part-titles">
              {title && (
                <div
                  className="title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              {subtitle && (
                <div
                  className="subtitle"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              )}
            </div>
            {desc && (
              <div
                className="left-part-desc"
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            )}
          </Column>
          <Column className="right-part">
            {image && <Image {...image} alt={image.alt} />}
          </Column>
        </Row>
      </Container>
    </section>
  )
}

export default TextAndImage
